body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/**
 * YUI 3.5.0 - reset.css (http://developer.yahoo.com/yui/3/cssreset/)
 * http://cssreset.com
 * Copyright 2012 Yahoo! Inc. All rights reserved.
 * http://yuilibrary.com/license/
 */
/*
	TODO will need to remove settings on HTML since we can't namespace it.
	TODO with the prefix, should I group by selector or property for weight savings?
*/
html{
	color:#000;
	background:#FFF;
}
/*
	TODO remove settings on BODY since we can't namespace it.
*/
/*
	TODO test putting a class on HEAD.
		- Fails on FF.
*/
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
	margin:0;
	padding:0;
}
table {
	border-collapse:collapse;
	border-spacing:0;
}
fieldset,
img {
	border:0;
}
/*
	TODO think about hanlding inheritence differently, maybe letting IE6 fail a bit...
*/
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
	font-style:normal;
	font-weight:normal;
}

ol,
ul {
	list-style:none;
}

caption,
th {
	text-align:left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-size:100%;
	font-weight:normal;
}
q:before,
q:after {
	content:'';
}
abbr,
acronym {
	border:0;
	font-feature-settings:normal;
	font-variant:normal;
}
/* to preserve line-height and selector appearance */
sup {
	vertical-align:text-top;
}
sub {
	vertical-align:text-bottom;
}
input,
textarea,
select {
	font-family:inherit;
	font-size:inherit;
	font-weight:inherit;
}
/*to enable resizing for IE*/
input,
textarea,
select {
	*font-size:100%;
}
/*because legend doesn't inherit in IE */
legend {
	color:#000;
}
/* YUI CSS Detection Stamp */
#yui3-css-stamp.cssreset { display: none; }

/* common parts */
body {
    font-family: "ヒラギノ丸ゴ Pro W4", "ヒラギノ丸ゴ Pro", "Hiragino Maru Gothic Pro", "heisei-maru-gothic-std", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro" !important;
  }

input  {
    outline: none;
    width: 100%;
    /* max-width: 300px; */
    margin: 1em auto;
    outline: 0;
    
}

textarea {
    outline: 0;
    resize: none;
}

button {
    width: 100%;
    max-width: 300px;
    cursor: pointer;
    line-height: 48px;
    font-size: 14px;
    border-radius: 30px;
    border: none;
    margin: 3em auto 1em;
    color: #fff;
    outline: 0;
}
@media screen and (max-width: 650px) {
    button {
        margin: 2.5em auto 2em;
  }
}

p,h2,h3,h4 {
    color: #747474;
    padding: 2.5em 0 0.5em 0;
}

@media screen and (max-width: 650px) {
    p,h2,h3,h4 {
        padding: 2em 0 0.5em 0;
  }
}

h2 {
    font-size: 1.7em;
    font-weight: bold;
}

h3 {
    font-size: 1.55em;
    font-weight: bold;
}

a{
    color: #0084be;
    text-decoration: none;
}

.categoryIcon {
    font-size: 1.2em;
    color: #1CD2F5;
}


/* layout */
.common__color{
    background-color: aqua;
}

.common__center{
    text-align: center;
}

.common__size{
    margin: 0 auto;
    max-width: 1100px;
    padding: 3em 0 4em 0;
 }
 @media screen and (max-width: 650px) {
  .common__size {
    margin: 3em 1em 4em 1em;
    padding: 0;
  }
}

.common__contents-center{
    padding: 1.5em 0;
    text-align: center;
}

/* theme color */
.back__gray {
    background-color: #fafafa;
}
.back__white {
    background-color: #fff;
}
/* 
.common__size {
 max-width: 1200px;
} */

.icon{
    margin: 0 0.35em;
    font-size: 3em;
}

.icon-twitter{
    color: #55acee;
}

.icon-facebook{
    color: #3B5998;
}

.icon-linkdin{
    color: #0e76a8;
}

.skill__title{
    color: #EFEFEF;
    padding: 0 0.5em 1em 0;
    font-size: 32px;
    text-align: left;
}

.skill__contents-center {
    padding: 2em 0;
    text-align: center;
}

.icon-brand {
    font-size: 1.5em;
}

.icon-brand-github {
    color: #171616;
}

.icon-brand-pc {
    color: #555;
}

.icon-brand-react {
    color: #52c1dd;
}

.icon-brand-js {
    color: #d5bf00;
}

.icon-brand-css {
    color: #00bfff;
}

.icon-brand-html {
    color: #ff8000;
}

.icon-brand-gulp {
    color: #dc143c;
}

.icon-brand-sass {
    color: #cd679a;
}

.icon-brand-wordpress {
    color: #00004d;
}

.icon-page {
    color: #ddd;
    font-size: 2em;
    padding: 0 0.5em;
}


.icon-pointer{
    cursor: pointer;
}

 /* images */
 .hobby-coding{
    background-image: url(/static/media/hobby_image_coding.9493c466.jpg);
 }

 .hobby-cooking{
    background-image: url(/static/media/hobby_image_walking.81828786.jpg);
 }

 .hobby-cycling{
    background-image: url(/static/media/hobby_image_roadbike.55b328a9.jpg);
 }


 .work-ec{
    background-image: url(/static/media/work_ec.d48985cc.jpg);
 }


 .work-blog{
    background-image: url(/static/media/work_blog.770e3a13.jpg);
 }

 .article-gulp{
    background-image: url(/static/media/blog-gulp.f1253676.jpg);
 }

 .article-reactSass{
    background-image: url(/static/media/blog-sass.b2ee1599.jpg);
 }

 .article-404{
    background-image: url(/static/media/blog-error.8e9330e2.png);
 }

 .work-portfolio{
    background-image: url(/static/media/work_portfolio.cd6d96a3.jpg);
 }

 .work-lp{
    background-image: url(/static/media/travellers_Pay.a175dd2c.jpg);
 }

 .work-blog{
    background-image: url(/static/media/work_blog.770e3a13.jpg);
 }

 .work-ec{
    background-image: url(/static/media/work_ec.d48985cc.jpg);
 }

 .work-app{
    background-image: url(/static/media/work_design_icon.ce2faa27.jpg);
 }

 .work-direction{
    -webkit-flex-flow: row-reverse;
            flex-flow: row-reverse;
 }

 .workDisplay{
    display: none !important;
 }

 .chip-html{
    background: linear-gradient(#ff8e1d,#ffb164);
 }

 .chip-css{
    background: linear-gradient(#52d7ff,#9dd5ff);
 }

 .chip-js{
    background: linear-gradient(#79d5a0,#84efb0);
 }

 .chip-react{
    background: linear-gradient(#7fdbe3,#469cdb);
 }

 .chip-psd{
    background: linear-gradient(#396df8,#76baf5);
 }

 .chip-ai{
    background: linear-gradient(#ff481d,#ffa843);
}

.chip-xd{
    background: linear-gradient(#df6aef,#fc99ed);
}

.chip-office{
    background: linear-gradient(#6bdb19,#bbf539);
}

.chip-office{
    background: linear-gradient(#6bdb19,#bbf539);
}

.chip-vscode{
    background: linear-gradient(#0d44db,#53b0ff);
}

.chip-spreadsheet{
    background: linear-gradient(#ffde29,#f8a31b);
}

.chip-qualification{
    background: #747474;
}






